.top-tags{
    bottom: auto;
    top: 65px;
}
.tags{
    color: #32CD30;
    opacity: 0.6;
    position: absolute;
    bottom: 0px;;
    left: 25px;
    font-size: 18px;
    font-family: 'La Belle Aurore';
}

.bottom-tag-html{
    margin-left: -20px;
}

@media only screen and (max-width: 575px) {
.tags{
    display: none;
}
    
}