.home-page-container{
  h1{
    color: white;
    font-size: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
  }
  h2{
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }
  .flat-button {
    color: #32CD30;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #32CD30;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #32CD30;
      color: #333;
    }
  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {}
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {}
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    #profilePic{
      max-width: 250px;
      margin-right: 20px;
    }
    #text{
      margin-left: 20px;
    }
    h1{
      font-size: 40px;
    }
    h2{
      font-size: 10px;
    }
  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    #profilePic{
      max-width: 300px;
      margin-right: 100px;
    }
    #text{
      margin-left: 100px;
    }
  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    #profilePic{
      max-width: 350px;
      margin-right: 150px;
    }
    #text{
      margin-left: 150px;
    }
    h1{
      font-size: 50px;
    }
  }
  @media only screen and (min-width: 1600px) {
    #profilePic{
      margin-right: 200px;
    }
    #text{
      margin-left: 200px;
    }
  }
}

.home-page-container-mobile{
  display: flex;
  flex-direction: column; 
  align-items: center; 
  padding: 20px; 


  .text-zone {
    margin-bottom: 0px; 
  }

  #profilePic {
    max-width: 225px;
    margin-top: -25px;
  }
  h1{
    color: white;
    font-size: 30px;
    font-family: 'Coolvetica';
    font-weight: 400;
  }
  h2{
    text-align: center;
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }
  .flat-button {
    color: #32CD30;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #32CD30;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;

    &:hover {
      background: #32CD30;
      color: #333;
    }
  }
  #profilePic{
    padding-top: 50px;
  }

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    #profilePic {
      margin-top: 0px;
    }
  }
}