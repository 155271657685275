.wc-navbar{
    background: #181818;
    height: 60px;
    z-index: 1000;
}


.logo{
  position: relative;
  padding-left: 10px;

}
.letter-logo{
  margin-left: 25px;
  z-index: 0;
}
.sub-logo{
  display:block;
  width: 75px;
  z-index: 1;
}
.navbar-middle-item{
  font-size: 20px;
}

.navbar-middle-item-text {
  color: whitesmoke !important;
}

.navbar-middle-item-text:hover {
  color: #32CD30 !important;
}
a.active{
  color: #32CD30 !important;
}
#linkedin{
  font-size: 35px;
  &:hover svg{
    color:  #0077b5;
  }
}
#github{
  font-size: 35px;
  &:hover svg{
    color:  #4183C4;
  }
}
#basic-navbar-nav{
  background: #181818;
}
@media only screen and (max-width: 575px) {
  .navbar-end-icons{
    color: whitesmoke !important;
    
  }
  #linkedin{
    font-size: 20px;
  }
  #github{
    font-size: 20px;
  }
}
