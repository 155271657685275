.about-page-container{
  padding-top: 100px;
  h1{
    color: white;
    font-size: 60px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
  }
  p{
    font-size: 18px;
    color: white;
    text-align: justify;
    font-family: sans-serif;
    font-weight: 300px;
    animation: pulse 2s;
    &:nth-of-type(1){
      animation-delay: 1s;
    }
    &:nth-of-type(2){
      animation-delay: 1.1s;
    }
    &:nth-of-type(3){
      animation-delay: 1.2s;
    }
  }
  .icons{
    display: inline;
    position: relative;
    margin-right: 2%;
    font-size: 40px;
    left: 15px;

  }
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    padding-top: 25px;
    h1{
      font-size: 40px;
    }
    p{
      font-size: 15px;
    }
    .about-text{
      width: 90%;
    }
    .about-tech-stack{
      width: 100%;
    }
    .icons{
      left: 0px;
  
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .about-text{
      width: 75%;
    }
  }
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {

  }
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {

  }
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .about-text{
      width: 50%;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 600px) {
    padding-top: 100px;
        
  }
}
