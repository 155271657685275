
.projects-page-container{
    .project-header-container{
        margin-top: 100px;
        h1{

            color: white;
            font-size: 53px;
            margin: 0;
            font-family: 'Coolvetica';
            font-weight: 400;
        }
    }
    .flat-button{
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: sans-serif;
        padding: 10px 18px;
        border: 1px solid white;
        margin-top: 25px;
        float: left;
        animation: fadeInAnimation 1s 1.8s backwards;
        white-space: nowrap;
    }
    
    .flat-button:hover,
    .flat-button2:hover {
        background: white;
        color: #333;
    }
    .project-card{
        width: 25rem; 
        padding:10px;
        background-color: #58181F;
        margin-bottom: 15px;
        margin-right: 15px;
        margin-left: 15px;
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        .project-header-container{
            margin-top: 20px;
        }
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {}
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {}
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {}
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {}
}